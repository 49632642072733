.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.App-link {
    color: rgb(97, 218, 251);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: logoFont;
    src: url(./assets/fonts/OpenSans-CondBold.ttf);
}

.navbar-brand {
    font-family: logoFont;
}

.company {
    font-family: logoFont;
}

.overlay {
    background-color: rgb(0, 0, 0);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.modal-content {
    color: #fff;
    background-image: linear-gradient(255deg, #f9b25e, #f17b30);
}

.table-cell-with-icon {
    float: left;
    margin-left: 10px;
}

table th,
table td {
    white-space: nowrap;
}

.map_container {
    position: relative;
}

.map-header {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: #281414;
    z-index: 10;
    margin: 0;
    padding: 0;
}